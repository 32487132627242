.overflow {
    overflow-x: hidden;
}

.white {
    background-color: white;
}

.align-custom {
    right: -33%;
}

.right-align {
    right: 26%;
}

.bg-light {
    background-color: #ffffff!important;
}

.navbar-toggle .icon-bar {
    background-color: #fff;
}

.padding{
    padding-top: 140px;
}

.core-value-text{
    padding-top: 2%;
    text-align: center;
    justify-content: center;
    z-index: 1;
    right: 43%;
    position: absolute;
    font-size: 2.5rem;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown-item:hover {
    background-color: dodgerblue;
}

.nav-link {
    font-size: 21px;
    color: black !important;
}

.nav-link:hover {
    color: dodgerblue !important;
}

.nav-item:hover {
    border-color: #818a91;
    color: white !important;
}

.mainLogo-navbar {
    height: 110px;
    width: 80px;
}

.mainLogo-navbar:hover {
    cursor: pointer;
}

.fix-top {
    position: absolute;
    z-index: 1;
    top: 0;
}

.banner-font-secondary {
    font-size: 18px;
}

.banner {
    height: 467px;
    width: 398px;
    opacity: 0.4;
}

.banner-font {
    color: black;
    text-align: center;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-image {
    transform: rotateZ(90deg);
    z-index: 1;
    position: absolute;
    top: -9%;
}

.banner-pt-image {
    border-radius: 8px;
    position: relative;
    box-shadow: 0 20px 30px rgba(0,0,0,0.5);
}

.banner-pt-font {
    /*text-align: right;*/
    color: #000;
    line-height: 1.4em;
    font-size: 45px;
}

.pt-banner{
    display: contents;
}

.red {
    color: red;
}

.ptc-banner-font {
    color: white;
    text-align: center;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-sub-header {
    color: black;
    font-size: 22px;
}

.header-font {
    display: inline-block;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 3rem;
}

.service-font {
    text-align: justify;
    color: #000;
    line-height: 1.4em;
    font-size: 18px;
}

.service-title-font {
    text-align: left;
    color: #000;
    line-height: 1.4em;
    font-size: 40px;
}

.wrap-text{
    overflow-wrap: break-word!important;
    white-space: inherit;
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}

.btn-link:hover, .btn-link:active, .btn-link:visited, .btn-link:focus {
    text-decoration: none;
}

.a:hover, .a:active, .a:visited, .a:focus {
    text-decoration: none;
}

.card:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.contact-logo {

}

.background-contact {
    z-index: -1;
    position: absolute;
}

.background-banner {
    color: dodgerblue;
    text-align: center;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.contact-title {
    text-align: left;
    color: #000;
    line-height: 1.4em;
    font-size: 30px;
}

.contact-logo {
    width: 103%
}

/*core component*/

.core-align {
    text-align: justify;
}

.gtco-feature .card .back-bg {
    margin-left: -30px !important;
    margin-top: -20px;
}

.gtco-feature .card svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.gtco-feature .card svg.defs {
    position: absolute;
    width: 0;
    height: 0;
}

.gtco-feature p small {
    font-size: 16px;
}

.gtco-feature a {
    margin-top: 20px;
    display: inline-block;
}

.gtco-features svg {
    position: absolute;
    margin-left: -100px;
    margin-top: 100px;
}

@media (max-width: 767px) {
    .gtco-features svg {
        margin: 200px auto 0;
    }
}

.gtco-features h2 {
    margin-top: 200px;
}

@media (max-width: 991px) {
    .gtco-features h2 {
        margin-top: 0;
    }
}

.gtco-features .col-lg-4 p {
    max-width: 320px;
    margin-bottom: 30px;
}

.gtco-features .col-lg-8 {
    padding: 10px 100px;
}

@media (max-width: 767px) {
    .gtco-features .col-lg-8 {
        padding: 10px 0;
    }
}

.gtco-features .col-lg-8 .row .col:first-child {
    margin-top: 150px;
}

@media (max-width: 600px) {
    .gtco-features .col-lg-8 .row .col:first-child {
        margin-top: 50px;
    }
}

@media (max-width: 600px) {
    .gtco-features .col-lg-8 .col {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.gtco-features .col-lg-8 .card {
    background: #fff;
    margin: 30px 5px;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.08);
}

.gtco-features .col-lg-8 .card .oval {
    background: linear-gradient(to right, #f1f6fd, #f0fbfe);
    padding: 10px;
    width: 150px;
    border-radius: 50%;
    transform: rotate(20deg);
    margin: 20px auto auto;
}

.gtco-features .col-lg-8 .card .card-img-top {
    max-width: 0.8in;
    margin: 0 auto;
    transform: rotate(-20deg);
}

.gtco-features .col-lg-8 .card h3 {
    font-size: 24px;
    color: #1d3ede;
}

.gtco-features .col-lg-8 .card p {
    font-size: 16px;
}

.card-img-top {
    max-width: 0.8in;
    margin: 0 auto;
    transform: rotate(-20deg);
}

.about-banner-height {
    height:500px;
}